import axios from "axios";

// //DEPLOY
const baseURL = "https://api.flight-times-gti.com/api/v1"; //PROD
//const baseURL = "https://uatapi.flight-times-gti.com/api/v1"; //UAT
//const baseURL = "https://api.dev-raiden.com/api/v1"; //DEV

//const baseURL = "http://localhost:3000/api/v1"; //DEV LOCAL

const instance = axios.create({
  baseURL,
});

export default instance;

import aad from "../services/aad";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

export const mixin = {
  data() {
    return {
      jan2100: 4102444800000, //UNIX Epoch Jan 1st, 2100 @ 00:00 UTC
      aircraftTypes: [
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],
      loadmasterRoles: [
        "LM",
        "LM2",
        "LM3",
        "LM4",
        "LM5",
        "CLM",
        "CLM2",
        "SLM",
        "SLM2",
        "SLM3",
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
      ],
      psrRoles: [
        "PSR",
        "PSR2",
        "PSR3",
        "PSR4",
        "PSR5",
        "SPSR",
        "SPSR2",
        "CPSR",
        "CPSR2",
        "LPSR",
        "LPSR2",
        "LPSR3",
        "LPSR4",
        "LPSR5",
        "Check PSR",
        "Lead PSR",
        "Senior PSR",
      ],
      cargoJobTitles: [
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster",
        "Loadmaster Training",
        "Contract Loadmaster",
        "Loadmaster Trainee",
        "Loadmaster/PSR",
        "Special Loads/CLC",
        "Mechanic",
        "Jumpseat",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
      ],
      passengerJobTitles: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "Loadmaster/PSR",
        "PSR/PAA Trainee",
        "POC",
        "GSC",
        "Mechanic",
        "Jumpseat",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
      ],
      countries: [
        "United States",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo (the Democratic Republic of the)",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Aland Islands",
      ],
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      timeTicks: [
        { index: 0, text: "1" },
        { index: 1, text: "2" },
        { index: 2, text: "3" },
        { index: 3, text: "4" },
        { index: 4, text: "5" },
        { index: 5, text: "6" },
        { index: 6, text: "7" },
        { index: 7, text: "8" },
        { index: 8, text: "9" },
        { index: 9, text: "10" },
        { index: 10, text: "11" },
        { index: 11, text: "12" },
        { index: 12, text: "13" },
        { index: 13, text: "14" },
        { index: 14, text: "15" },
        { index: 15, text: "16" },
        { index: 16, text: "17" },
        { index: 17, text: "18" },
        { index: 18, text: "19" },
        { index: 19, text: "20" },
        { index: 20, text: "21" },
        { index: 21, text: "22" },
        { index: 22, text: "23" },
      ],
      perdiemExclusion: [
        "TJAB",
        "TJBQ",
        "TJRV",
        "TJPS",
        "TJSJ",
        "TIST",
        "TISX",
      ],
      version: "1.24.0", //Deploy
      build: "103020231129", //Deploy
      environment: "PROD", //Deploy
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    employeeProfileImagesBucketUrl() {
      switch (this.environment) {
        case "DEV":
          return "https://dev-monarch-employee-profile-images.s3.amazonaws.com/";
        case "UAT":
          return "https://uat-monarch-employee-profile-images.s3.amazonaws.com/";

        default:
          return "https://monarch-employee-profile-images.s3.amazonaws.com/";
      }
    },

    navbarSettings() {
      switch (this.environment) {
        case "DEV":
          return {
            style: {
              backgroundColor: "#ff0000",
              color: "#fff",
            },
            text: "Atlas Air - DEV",
          };
        case "UAT":
          return {
            style: {
              backgroundColor: "#ff0000",
              color: "#fff",
            },
            text: "Atlas Air - UAT",
          };

        default:
          return {
            style: {
              backgroundColor: "#002663",
              color: "#ffb608",
            },
            text: "Atlas Air",
          };
      }
    },

    //-----------------------------------Admin Manual
    enableSeeAdminManual() {
      return this.user.securityGroup.authorizations.flightAssignment.create
        .enabled;
    },

    //-----------------------------------Flight (Payload)
    enableUpdateFlight() {
      return this.user.securityGroup.authorizations.flight.update.enabled;
    },

    //-----------------------------------Flight (Remarks)
    enableUpdateFlightRemarks() {
      return this.user.securityGroup.authorizations.flight.update.allowedFields.includes(
        "Remarks"
      );
    },

    enableReadFlight() {
      return this.user.securityGroup.authorizations.flight.read.enabled;
    },

    enableGetAircraftScheduleConflicts() {
      return this.user.securityGroup.authorizations.flight.read
        .getAircraftScheduleConflicts;
    },

    //-----------------------------------Warnings
    enableSeeAssignmentWarnings() {
      return this.user.securityGroup.authorizations.flightAssignment.create
        .enabled;
    },

    //-----------------------------------Bidlines
    enableCreateBidlines() {
      return this.user.securityGroup.authorizations.bidline.create.enabled;
    },
    enableReadBidlines() {
      return this.user.securityGroup.authorizations.bidline.read.enabled;
    },
    enableUpdateBidline() {
      return this.user.securityGroup.authorizations.bidline.update.enabled;
    },

    enableDeleteBidline() {
      return this.user.securityGroup.authorizations.bidline.delete.enabled;
    },

    //-----------------------------------Flight Assignments
    enableCreateTripAssignments() {
      //Only users with "Atlas Air" in companies can make trip assignments
      return (
        this.user.securityGroup.authorizations.flightAssignment.create
          .enabled && this.user.securityGroup.companies.includes("Atlas Air")
      );
    },

    enableCreateFlightAssignments() {
      return this.user.securityGroup.authorizations.flightAssignment.create
        .enabled;
    },

    enableReadMyAssignments() {
      return (
        this.user.securityGroup.authorizations.flightAssignment.read.enabled ||
        this.user.securityGroup.authorizations.groundAssignment.read.enabled
      );
    },

    enableReadFlightAssignments() {
      return (
        this.user.securityGroup.authorizations.flightAssignment.read.enabled &&
        this.user.securityGroup.authorizations.flightAssignment.read.level ===
          "Manager"
      );
    },

    enableUpdateFlightAssignment() {
      return this.user.securityGroup.authorizations.flightAssignment.update
        .enabled;
    },

    enableDeleteFlightAssignment() {
      return this.user.securityGroup.authorizations.flightAssignment.delete
        .enabled;
    },

    //-----------------------------------Ground Assignments
    enableCreateGroundAssignments() {
      return this.user.securityGroup.authorizations.groundAssignment.create
        .enabled;
    },
    enableReadMyGroundAssignments() {
      return this.user.securityGroup.authorizations.groundAssignment.read
        .enabled;
    },

    enableReadGroundAssignments() {
      return (
        this.user.securityGroup.authorizations.groundAssignment.read.enabled &&
        this.user.securityGroup.authorizations.groundAssignment.read.level ===
          "Manager"
      );
    },
    enableUpdateGroundAssignment() {
      return this.user.securityGroup.authorizations.groundAssignment.update
        .enabled;
    },
    enableDeleteGroundAssignment() {
      return this.user.securityGroup.authorizations.groundAssignment.delete
        .enabled;
    },

    //---------------------------------Assignment Notifications
    enableCreateAssignmentNotification() {
      return this.user.securityGroup.authorizations.assignmentNotification
        .create.enabled;
    },

    enableReadAllAssignmentNotification() {
      if (
        this.user.securityGroup.authorizations.assignmentNotification.read
          .enabled
      ) {
        const authorizedLevels = ["Manager"];
        if (
          authorizedLevels.includes(
            this.user.securityGroup.authorizations.assignmentNotification.read
              .level
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    enableReadMyAssignmentNotification() {
      if (
        this.user.securityGroup.authorizations.assignmentNotification.read
          .enabled
      ) {
        const authorizedLevels = ["Manager", "Employee"];
        if (
          authorizedLevels.includes(
            this.user.securityGroup.authorizations.assignmentNotification.read
              .level
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    enableUpdateAssignmentNotification() {
      return this.user.securityGroup.authorizations.assignmentNotification
        .update.enabled;
    },
    enableDeleteAssignmentNotification() {
      return this.user.securityGroup.authorizations.assignmentNotification
        .delete.enabled;
    },

    //-----------------------------------Employee Profiles
    enableCreateEmployee() {
      return this.user.securityGroup.authorizations.employee.create.enabled;
    },

    enableReadEmployee() {
      return this.user.securityGroup.authorizations.employee.read.enabled;
    },

    enableReadEmployeeContactInformation() {
      return (
        this.user.securityGroup.authorizations.employee.read.level === "Manager"
      );
    },

    enableUpdateEmployee() {
      return (
        this.user.securityGroup.authorizations.employee.update.enabled &&
        (this.user.securityGroup.authorizations.employee.update.level ===
          "Employee" ||
          this.user.securityGroup.authorizations.employee.update.level ===
            "Manager")
      );
    },

    enableUpdateEmployeeSecurityGroup() {
      return this.user.securityGroup.authorizations.employee.update
        .securityGroup;
    },

    enableUpdateEmployeeAdvanced() {
      return (
        this.user.securityGroup.authorizations.employee.update.enabled &&
        this.user.securityGroup.authorizations.employee.update.level ===
          "Manager"
      );
    },

    enableDeleteEmployee() {
      return this.user.securityGroup.authorizations.employee.delete.enabled;
    },

    //-----------------------------------Payroll Reports
    //Export Payroll Reports
    enableExportPayrollReportsToCsv() {
      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.read.level ===
          "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.read.level ===
            "Administrator")
      );
    },

    //Create
    enableCreateEmployeePayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.create.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.create.level ===
          "Employee" ||
          this.user.securityGroup.authorizations.payrollReport.create.level ===
            "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.create.level ===
            "Administrator")
      );
    },

    enableCreateManagerPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.create.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.create.level ===
          "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.create.level ===
            "Administrator")
      );
    },

    enableCreateAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.create.enabled &&
        this.user.securityGroup.authorizations.payrollReport.create.level ===
          "Administrator"
      );
    },

    //Read
    enableReadEmployeePayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.read.level ===
          "Employee" ||
          this.user.securityGroup.authorizations.payrollReport.read.level ===
            "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.read.level ===
            "Administrator")
      );
    },

    enableReadManagerPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.read.level ===
          "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.read.level ===
            "Administrator")
      );
    },

    enableReadAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        this.user.securityGroup.authorizations.payrollReport.read.level ===
          "Administrator"
      );
    },

    //Update
    enableUpdateEmployeePayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.update.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.update.level ===
          "Employee" ||
          this.user.securityGroup.authorizations.payrollReport.update.level ===
            "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.update.level ===
            "Administrator")
      );
    },

    enableUpdateManagerPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.update.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.update.level ===
          "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.update.level ===
            "Administrator")
      );
    },

    enableUpdateAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.update.enabled &&
        this.user.securityGroup.authorizations.payrollReport.update.level ===
          "Administrator"
      );
    },

    //Delete
    enableDeleteEmployeePayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.delete.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.delete.level ===
          "Employee" ||
          this.user.securityGroup.authorizations.payrollReport.delete.level ===
            "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.delete.level ===
            "Administrator")
      );
    },

    enableDeleteManagerPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.delete.enabled &&
        (this.user.securityGroup.authorizations.payrollReport.delete.level ===
          "Manager" ||
          this.user.securityGroup.authorizations.payrollReport.delete.level ===
            "Administrator")
      );
    },

    enableDeleteAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.delete.enabled &&
        this.user.securityGroup.authorizations.payrollReport.delete.level ===
          "Administrator"
      );
    },

    //-------------Security Groups
    enableCreateSecurityGroup() {
      return this.user.securityGroup.authorizations.securityGroup.create
        .enabled;
    },

    enableReadSecurityGroup() {
      return this.user.securityGroup.authorizations.securityGroup.read.enabled;
    },

    enableUpdateSecurityGroup() {
      return this.user.securityGroup.authorizations.securityGroup.update
        .enabled;
    },

    enableDeleteSecurityGroup() {
      return this.user.securityGroup.authorizations.securityGroup.delete
        .enabled;
    },

    //-------------Airports
    enableReadAirport() {
      return this.user.securityGroup.authorizations.airport.read.enabled;
    },
    enableUpdateAirport() {
      return this.user.securityGroup.authorizations.airport.update.enabled;
    },

    enableUpdateCrewRequirements() {
      return this.user.securityGroup.authorizations.airport.update.enabled;
    },

    currentTimeX() {
      const ct = Date.now();
      const minutes = Math.ceil(
        (ct - new Date(this.dateFilter.start).getTime()) / 1000 / 60
      );

      if (minutes > 0) {
        return minutes / this.scale;
      } else {
        return 0;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    companyAirports() {
      return this.$store.getters.companyAirports;
    },

    company() {
      return this.$store.getters.company;
    },
  },
  methods: {
    formatStationAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);

      return `${assignment.originIata} . ${
        assignment.subCategory
      } . ${timeOut} Z - ${timeIn} Z . ${
        assignment.group.flightIds.length
      } Assignment(s) . ${this.formatMinutes(assignment.duration)}`;
    },

    formatGroundAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const updatedAt = this.formatDateTime(assignment.updatedAt);

      switch (assignment.category) {
        case "Special Assignment":
        case "Layover":
        case "Quarantine":
        case "Sick":
          return `${assignment.category} . ${assignment.originIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Travel":
          return `${assignment.category} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Leave":
          return `${assignment.category} . ${assignment.subCategory} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Training":
          return `${assignment.category} . ${assignment.subCategory} . ${assignment.originIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        default:
          return `${assignment.category} . ${timeOut} . Assigned by: ${assignment.assignedBy} on ${updatedAt} z.`;
      }
    },

    formatFlightAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.flight.effectiveTimeOut);
      const updatedAt = this.formatDateTime(assignment.updatedAt);
      const flightRole = this.getFlightRole(assignment.role).description;

      return `Flight Assignment . ${flightRole} . ${assignment.flight.flightNumber} . ${assignment.flight.aircraftRegistration} . ${assignment.flight.originIata}-${assignment.flight.destinationIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
    },

    formatElapsedTime(t) {
      const addLeadingZeros = (number) => {
        return String(number).padStart(2, "0");
      };

      const h = addLeadingZeros(Math.floor(t / 60));
      const m = addLeadingZeros(t % 60);

      return `${h}+${m}`;
    },
    formatStationAssignmentFlight(flight, stationIata) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const timeIn = this.formatDateTime(flight.effectiveTimeIn);

      let aircraftType = "PAX";

      if (flight.aircraft.cargoOnly) {
        aircraftType = "CARGO";
      }

      let snapshot = "---";

      if (flight.originIata === stationIata) {
        snapshot = `${aircraftType} - DEP: ${timeOut}  | ${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata}`;
      } else {
        snapshot = `${aircraftType} - ARR: ${timeIn}  | ${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata}`;
      }

      return snapshot;
    },
    async printActivityReport(employee) {
      try {
        const miniRows = [
          "Stand By",
          "Day Off",
          "Vacation",
          "Holiday",
          "Leave",
          "LOA",
        ];

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        pdfDoc.setTitle(
          `${employee.givenName.substring(0, 1)} ${
            employee.surname
          } Act-Rept  - ${this.formatDate(this.monthFilter.endDate).substring(
            3,
            11
          )}`
        );

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let day;
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(
          `${this.formatName(employee.givenName, employee.surname, "sub")} - (${
            employee.gatewayCode
          }) - Activity Report: ${new Date(this.monthFilter.startDate)
            .toISOString()
            .substring(8, 2)} - ${this.formatDate(this.monthFilter.endDate)}`,
          options
        );

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        //----------------------HEADER ROW 1
        options.color = rgb(0.15, 0.09, 0.81);
        options.y -= 30;
        options.x = 50;
        page.drawText(
          `Work Days 1-15: ${employee.activity.workDaysFirstHalf}`,
          options
        );

        options.x = 225;
        page.drawText(
          `Work Days 16-${employee.activity.daysInThisMonth}: ${employee.activity.workDaysSecondHalf}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Work Days Total: ${employee.activity.workDays}`,
          options
        );

        //----------------------HEADER ROW 2
        options.y -= 15;
        options.x = 50;
        page.drawText(`Vacation: ${employee.activity.vacationDays}`, options);

        options.x = 225;
        page.drawText(
          `Floating Holidays: ${employee.activity.floatingHolidayDays}`,
          options
        );

        options.x = 400;
        page.drawText(`Holidays: ${employee.activity.holidayDays}`, options);

        //----------------------HEADER ROW 3
        options.y -= 15;
        options.x = 50;
        page.drawText(`Sick: ${employee.activity.sickDays}`, options);

        options.x = 225;
        page.drawText(`LOA Days: ${employee.activity.loaDays}`, options);

        options.x = 400;
        page.drawText(`Leave: ${employee.activity.leaveDays}`, options);

        //----------------------HEADER ROW 4
        //TEMP DISABLED
        // options.y -= 15;
        // options.x = 50;
        // options.color = rgb(0, 0, 0);
        // page.drawText(
        //   `Domestic Perdiem: ${employee.activity.perdiemDays}`, //DOMESTIC PERDIEM
        //   options
        // );

        // options.x = 225;
        // page.drawText(
        //   `International Perdiem: ${employee.activity.internationalPerdiemDays}`, //INTERNATIONAL PERDIEM
        //   options
        // );

        // options.x = 400;
        // page.drawText(
        //   `Total Perdiem: ${employee.activity.combinedPerdiemDays}`,
        //   options
        // );

        //----------------------HEADER ROW 5
        //TEMP DISABLED
        // options.y -= 15;
        // options.x = 50;
        // page.drawText(`Total Days: ${employee.activity.totalDays}`, options);

        // options.x = 225;
        // page.drawText(
        //   `Days off/STBY: ${employee.activity.daysOff}/${employee.activity.standByDays}`,
        //   options
        // );

        options.y -= 15;
        options.x = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Total Days: ${employee.activity.totalDays}`, options);

        options.x = 225;
        page.drawText(
          `Perdiem: ${employee.activity.combinedPerdiemDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Days off/STBY: ${employee.activity.daysOff}/${employee.activity.standByDays}`,
          options
        );

        //----------------------HEADER ROW 6
        options.y -= 15;
        options.x = 50;
        options.color = rgb(1, 0, 0);
        page.drawText(
          `Mission Hazard: ${employee.activity.missionHazard}`,
          options
        );

        options.x = 225;
        page.drawText(
          `COVID Days: ${employee.activity.healthRiskDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `COVID Allowance: ${employee.activity.healthRiskAllowance}`,
          options
        );

        //---------------------------------------------------Separator
        options.color = rgb(0, 0, 0);
        options.y -= 15;
        options.x = 50;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 15;

        let dayPerdiem;

        for (let i = 0; i < employee.activity.days.length; i++) {
          day = employee.activity.days[i];

          //---------------------------------------------------ROW 1
          // Date
          options.x = 50;
          page.drawText(`${day.date} - ${day.data.description}`, options);

          if (!miniRows.includes(day.data.description)) {
            //TEMP DISABLED
            // if (day.data.internationalPerdiem > 0) {
            //   dayPerdiem = "International";
            // } else if (day.data.perdiem > 0) {
            //   dayPerdiem = "Domestic";
            // } else {
            //   dayPerdiem = "No Perdiem";
            // }

            if (day.data.internationalPerdiem > 0) {
              dayPerdiem = "Yes";
            } else if (day.data.perdiem > 0) {
              dayPerdiem = "Yes";
            } else {
              dayPerdiem = "No";
            }

            //---------------------------------------------------ROW 2
            options.y -= 15;
            // Perdiem
            options.x = 50;
            page.drawText(`Perdiem: ${dayPerdiem}`, options);
            // Hazards,
            options.x = 225;
            page.drawText(`Mission HAZ: ${day.data.missionHazard}`, options);

            // Health Risk
            options.x = 400;
            page.drawText(
              `COVID 19: ${day.data.healthRisk > 0 ? "1" : "0"}`,
              options
            );
          }
          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `_________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === employee.activity.days.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 70 < 50 && i <= employee.activity.days.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
    async logout() {
      this.$store.commit("updateUser", null);
      aad.logout();
    },

    getDayDescriptionAndCodes(assignment) {
      let code = "---";
      let displayCode = "---";
      let backgroundColor = "red";
      let color = "#FFFFFF";

      if (assignment.type === "Ground") {
        switch (assignment.category) {
          case "Stand By":
            code = "STBY";
            displayCode = "STBY";
            backgroundColor = "#FFD394";
            color = "#000000";
            break;
          case "Station Assignment":
            code = "STA";
            displayCode = "STA ";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Special Assignment":
            code = "SPAS";
            displayCode = "SPAS";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Travel":
            code = "TRVL";
            displayCode = assignment.destinationIata;
            backgroundColor = "#F6F110";
            color = "#0000FF";
            break;
          case "Layover":
            code = "LYVR";
            displayCode = "LYVR";
            backgroundColor = "#FFFFFF";
            color = "#0000FF";
            break;
          case "Training":
            code = "TRNG";
            displayCode = "TRNG";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Sick":
            code = "SICK";
            displayCode = "SICK";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Quarantine":
            code = "QUA";
            displayCode = "QUA";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Quarantine - Day OFF":
            code = "QDO";
            displayCode = "QDO";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Vacation":
            code = "VAC";
            displayCode = "VAC";
            backgroundColor = "#00FFCC";
            color = "#000000";
            break;
          case "Holiday":
            code = "HOL";
            displayCode = "HOL";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Floating Holiday":
            code = "FHOL";
            displayCode = "FHOL";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Leave":
            code = "LEAV";
            displayCode = "LEAV";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "LOA":
            code = "LOA";
            displayCode = "LOA";
            backgroundColor = "#FFFFFF";
            color = "#000000";
            break;
          case "Do Not Use":
            code = "DNU";
            displayCode = "DNU";
            backgroundColor = "#f56308";
            color = "#000000";
            break;

          default:
            break;
        }
        return {
          description: this.formatGroundAssignmentSnapshotShort(assignment),
          code,
          displayCode,
          backgroundColor,
          color,
        };
      } else {
        return {
          description: this.formatFlightSnapshotShort(assignment.flight),
          code: "FLT",
          displayCode: assignment.destinationIata,
          backgroundColor: "#FFFFFF",
          color: "#000000",
        };
      }
    },

    getDayData(assignments, dayStart, dayEnd, gatewayCode) {
      //PERDIEM  Calculation of Perdiem happens here
      let dayData = {
        description: "",
        code: "",
        displayCode: "",
        backgroundColor: "red",
        color: "#FFFFFF",
        work: 0,
        vacation: 0,
        sick: 0,
        off: 0,
        standBy: 0,
        loa: 0,
        leave: 0,
        holiday: 0,
        floatingHoliday: 0,
        healthRisk: 0,
        perdiem: 0,
        internationalPerdiem: 0,
      };

      // 24 Hour Assignments Categories
      // "Stand By",
      // "Sick",
      // "Quarantine",
      // "Quarantine - Day OFF",
      // "Vacation",
      // "Holiday",
      // "Floating Holiday",
      // "Leave",
      // "LOA",

      // Ground Assignment Categories: [
      //   "Stand By",
      //   "Station Assignment",
      //   "Special Assignment",
      //   "Travel",
      //   "Layover",
      //   "Training",
      //   "Sick",
      //   "Quarantine",
      //   "Quarantine - Day OFF",
      //   "Vacation",
      //   "Holiday",
      //   "Floating Holiday",
      //   "Leave",
      //   "LOA",
      // ],

      if (assignments.length) {
        let startsToday;
        let endsToday;
        let startsBeforeToday;
        let endsAfterToday;

        let assignment; //Holds the assignment value when looping thru assignments, retains the last assignment
        let assignmentStart; //Assignment start time in Unix Epoch
        let assignmentEnd; //Assignment end time in Unix Epoch

        let origin;
        let destination;

        //These categories count as a working day
        const workAssignmentCategories = [
          "Stand By",
          "Station Assignment",
          "Special Assignment",
          "Travel",
          "Layover",
          "Training",
          "Sick",
          "Quarantine",
          "Do Not Use",
        ];

        const perdiemAssignmentCategories = [
          "Station Assignment",
          "Special Assignment",
          "Travel",
          "Layover",
          "Training",
          "Sick",
          "Quarantine",
          "Do Not Use",
        ];

        const healthRiskAssignmentCategories = [
          "Station Assignment",
          "Special Assignment",
          "Travel",
          "Layover",
          "Training",
          "Sick",
          "Quarantine",
          "Do Not Use",
        ];

        let descriptionAndCodes;
        let payInternationalPerdiem = 0;

        for (let i = 0; i < assignments.length; i++) {
          //PERDIEM

          assignment = assignments[i];

          origin = this.companyAirports.find((airport) => {
            return airport.iata === assignment.originIata;
          });

          destination = this.companyAirports.find((airport) => {
            return airport.iata === assignment.destinationIata;
          });

          //TEMP DISABLED
          // if (
          //   (origin.country !== "UNITED STATES" &&
          //     !this.perdiemExclusion.includes(origin.icao)) ||
          //   (destination.country !== "UNITED STATES" &&
          //     !this.perdiemExclusion.includes(destination.icao))
          // ) {
          //   payInternationalPerdiem++;
          // }

          assignmentStart = new Date(assignment.startTime).getTime();
          assignmentEnd = new Date(assignment.endTime).getTime();

          startsToday =
            assignmentStart >= dayStart && assignmentStart <= dayEnd;
          endsToday = assignmentEnd >= dayStart && assignmentEnd <= dayEnd;
          startsBeforeToday = assignmentStart < dayStart;
          endsAfterToday = assignmentEnd > dayEnd;

          if (startsBeforeToday && endsAfterToday) {
            //The employee started to the assignment before the start of the day and ended the assignment after the day ended
            //Check assignment origin to calculate health risk and Perdiem

            if (assignment.type === "Ground") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.category) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA":
                  dayData.loa++;
                  break;
                case "Leave":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;
                  break;
                default:
                  break;
              }

              //Work Day based on Category
              if (workAssignmentCategories.includes(assignment.category)) {
                dayData.work++;
              }

              //Health Risk based on Origin
              if (
                healthRiskAssignmentCategories.includes(assignment.category) &&
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            } else {
              //---------------------------------------------Flight Assignment

              //Work Day
              dayData.work++;

              //Health Risk based on Origin
              if (
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          } else if (startsToday && endsToday) {
            //The employee started the assignment on or after the start of the day and ended the assignment before the day ended
            //Check assignment origin and assignment destination to calculate health risk and Perdiem

            if (assignment.type === "Ground") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.category) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA":
                  dayData.loa++;
                  break;
                case "Leave":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;
                  break;
                default:
                  break;
              }

              //Work Day based on Category
              if (workAssignmentCategories.includes(assignment.category)) {
                dayData.work++;
              }

              //Health Risk based on Origin

              if (
                healthRiskAssignmentCategories.includes(assignment.category) &&
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.destinationIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Health Risk based on Destination

              if (
                healthRiskAssignmentCategories.includes(assignment.category) &&
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }
            } else {
              //---------------------------------------------Flight Assignment

              //Work Day
              dayData.work++;

              //Health Risk based on Origin
              if (
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Health Risk based on Destination

              if (
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Destination
              if (gatewayCode !== assignment.destinationIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          } else if (startsBeforeToday && endsToday) {
            //The employee started the assignment  before the start of the day and ended the assignment before the day ended
            //Check assignment destination to calculate health risk and Perdiem

            if (assignment.type === "Ground") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.category) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA":
                  dayData.loa++;
                  break;
                case "Leave":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;
                  break;
                default:
                  break;
              }

              //Work Day based on Category
              if (workAssignmentCategories.includes(assignment.category)) {
                dayData.work++;
              }

              //Health Risk based on Destination
              if (
                healthRiskAssignmentCategories.includes(assignment.category) &&
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.destinationIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            } else {
              //---------------------------------------------Flight Assignment
              //Work Day
              dayData.work++;

              //Health Risk based on Destination
              if (
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination
              if (gatewayCode !== assignment.destinationIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          } else if (startsToday && endsAfterToday) {
            //The employee started the assignment on or after the start of the day and ended the assignment after the day ended
            //Check assignment origin to calculate health risk and Perdiem

            if (assignment.type === "Ground") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.category) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA":
                  dayData.loa++;
                  break;
                case "Leave":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;
                  break;
                default:
                  break;
              }

              //Work Day based on Category
              if (workAssignmentCategories.includes(assignment.category)) {
                dayData.work++;
              }

              //Health Risk based on Origin
              if (
                healthRiskAssignmentCategories.includes(assignment.category) &&
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentCategories.includes(assignment.category) &&
                (assignment.category === "Special Assignment" ||
                  gatewayCode !== assignment.destinationIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            } else {
              //---------------------------------------------Flight Assignment

              //Work Day
              dayData.work++;

              //Health Risk based on Origin
              if (
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination
              if (gatewayCode !== assignment.destinationIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          }

          descriptionAndCodes = this.getDayDescriptionAndCodes(assignment);
          dayData.description = descriptionAndCodes.description;
          dayData.code = descriptionAndCodes.code;
          dayData.displayCode = descriptionAndCodes.displayCode;
          dayData.backgroundColor = descriptionAndCodes.backgroundColor;
          dayData.color =
            dayData.healthRisk > 0 || dayData.missionHazard > 0
              ? "#FF0000"
              : descriptionAndCodes.color;
        }
      } else {
        dayData.description = "Day Off";
        dayData.code = "OFF";
        dayData.displayCode = "OFF";
        dayData.backgroundColor = "#F0FFFF";
        dayData.color = "#000000";
        dayData.off = 1;
      }

      return dayData;
    },

    getEmployeeActivity(employee) {
      let date;
      let dayStart;
      let dayEnd;
      let dayAssignments;
      let dayGroundSegments;
      let dayHazardSegments;

      let hazardDayCredit;

      const monthGroundSegments = [];
      let assignment;
      let nextAssignment;
      let groundSegment;
      let duration;

      let airport;

      let activity = {
        daysInThisMonth: this.monthFilter.days.length,
        days: [],
        perdiemDaysFirstHalf: 0,
        perdiemDaysSecondHalf: 0,
        perdiemDays: 0,
        internationalPerdiemDaysFirstHalf: 0,
        internationalPerdiemDaysSecondHalf: 0,
        internationalPerdiemDays: 0,
        combinedPerdiemDays: 0,
        workDays: 0,
        workDaysFirstHalf: 0,
        workDaysSecondHalf: 0,
        daysOff: 0,
        standByDays: 0,
        vacationDays: 0,
        sickDays: 0,
        loaDays: 0,
        leaveDays: 0,
        holidayDays: 0,
        floatingHolidayDays: 0,
        healthRiskDays: 0,
        missionHazard: 0,
        totalDays: 0,
        daysOver20: 0,
        daysOverGuarrantee: 0,
      };

      //Get month ground segments
      for (let i = 0; i < employee.assignments.length; i++) {
        assignment = employee.assignments[i];
        nextAssignment = employee.assignments[i + 1];

        if (nextAssignment) {
          duration = Math.floor(
            (new Date(nextAssignment.startTime).getTime() -
              new Date(assignment.endTime).getTime()) /
              60 /
              1000
          );

          groundSegment = {
            startIata: assignment.destinationIata,
            startTime: assignment.endTime,
            endIata: nextAssignment.originIata,
            endTime: nextAssignment.startTime,
            duration,
          };

          monthGroundSegments.push(groundSegment);
        } else {
          const endTime = new Date(
            `${assignment.endTime.substring(0, 10)}T23:59:59.999+00:00`
          ).toISOString();

          duration = Math.floor(
            (new Date(endTime).getTime() -
              new Date(assignment.endTime).getTime()) /
              60 /
              1000
          );

          //Open ground segment ends 1 minute before midnight on the last assignment
          groundSegment = {
            startIata: assignment.destinationIata,
            startTime: assignment.endTime,
            endIata: assignment.destinationIata,
            endTime,
            duration,
          };

          monthGroundSegments.push(groundSegment);
        }
      }

      //--------------------------------------------------------------------
      //          MISSION HAZ - HEALTH RISK - PERDIEM - DAYS
      //--------------------------------------------------------------------

      let dayOfTheMonth;

      //Loop thru days
      for (let i = 0; i < this.monthFilter.days.length; i++) {
        //Reset variables
        dayHazardSegments = new Map();
        hazardDayCredit = 0;

        //Get day date text, start and end timestamps in Unix Epoch
        date = this.monthFilter.days[i].substring(0, 10);
        dayStart = new Date(`${date}T00:00:00.000+00:00`).getTime();
        dayEnd = new Date(`${date}T23:59:59.999+00:00`).getTime();

        //Day ground segments
        dayGroundSegments = monthGroundSegments.filter((groundSegment) => {
          return (
            new Date(groundSegment.startTime).getTime() <= dayEnd &&
            new Date(groundSegment.endTime).getTime() >= dayStart
          );
        });

        //Loop thru day ground segments
        if (dayGroundSegments.length) {
          dayGroundSegments.forEach((groundSegment) => {
            //Count Hazard
            if (groundSegment.startTime.substring(0, 10) === date) {
              airport = this.companyAirports.find((a) => {
                return a.iata === groundSegment.startIata;
              });

              if (
                airport &&
                this.overlaps(dayStart, dayEnd, airport.hazardPeriods)
              ) {
                dayHazardSegments.set(groundSegment.startIata, groundSegment);
              }
            }
          });
        }

        if (dayHazardSegments.size > 0) {
          dayHazardSegments.forEach((groundSegment) => {
            //Rule definition: on the ground 8 hours or more. Ground time must be in different days
            if (groundSegment.duration >= 8 * 60) {
              if (
                groundSegment.startTime.substring(0, 10) !==
                groundSegment.endTime.substring(0, 10)
              ) {
                hazardDayCredit += 2;
              } else {
                hazardDayCredit += 1;
              }
            } else {
              hazardDayCredit += 1;
            }
          });
        }

        //Day assignments
        dayAssignments = employee.assignments.filter((assignment) => {
          return (
            new Date(assignment.startTime).getTime() <= dayEnd &&
            new Date(assignment.endTime).getTime() >= dayStart
          );
        });

        let dayData = this.getDayData(
          dayAssignments,
          dayStart,
          dayEnd,
          employee.gatewayCode
        );

        if (hazardDayCredit > 2) {
          dayData.missionHazard = 2;
        } else {
          dayData.missionHazard = hazardDayCredit;
        }

        dayData.assignments = dayAssignments;

        dayOfTheMonth = parseInt(date.substring(8, 10));

        //International Perdiem
        if (dayData.internationalPerdiem > 0) {
          if (dayOfTheMonth > 15) {
            activity.internationalPerdiemDaysSecondHalf++;
          } else {
            activity.internationalPerdiemDaysFirstHalf++;
          }
        } else {
          //Domestic Perdiem
          if (dayData.perdiem > 0) {
            if (dayOfTheMonth > 15) {
              activity.perdiemDaysSecondHalf++;
            } else {
              activity.perdiemDaysFirstHalf++;
            }
          }
        }

        if (dayData.work > 0) {
          if (dayOfTheMonth > 15) {
            activity.workDaysSecondHalf++;
          } else {
            activity.workDaysFirstHalf++;
          }
        }

        if (dayData.vacation > 0) {
          activity.vacationDays++;
        }

        if (dayData.sick > 0) {
          activity.sickDays++;
        }

        if (dayData.off > 0) {
          activity.daysOff++;
        }

        if (dayData.standBy > 0) {
          activity.standByDays++;
        }

        if (dayData.loa > 0) {
          activity.loaDays++;
        }

        if (dayData.leave > 0) {
          activity.leaveDays++;
        }

        if (dayData.holiday > 0) {
          activity.holidayDays++;
        }

        if (dayData.floatingHoliday > 0) {
          activity.floatingHolidayDays++;
        }

        if (dayData.healthRisk > 0) {
          activity.healthRiskDays++;
        }

        activity.missionHazard += hazardDayCredit;

        activity.days.push({
          date,
          data: dayData,
        });
      }

      activity.workDays =
        activity.workDaysFirstHalf + activity.workDaysSecondHalf;
      activity.perdiemDays =
        activity.perdiemDaysFirstHalf + activity.perdiemDaysSecondHalf;
      activity.internationalPerdiemDays =
        activity.internationalPerdiemDaysFirstHalf +
        activity.internationalPerdiemDaysSecondHalf;
      (activity.combinedPerdiemDays =
        activity.perdiemDays + activity.internationalPerdiemDays),
        (activity.healthRiskAllowance = activity.healthRiskDays / 2);
      activity.totalDays =
        activity.workDays +
        activity.vacationDays +
        activity.daysOff +
        activity.loaDays +
        activity.leaveDays +
        activity.holidayDays +
        activity.floatingHolidayDays;
      activity.daysOver20 = activity.workDays > 20 ? activity.workDays - 20 : 0;
      activity.daysOverGuarrantee =
        activity.daysOver20 +
        activity.missionHazard +
        activity.healthRiskAllowance;

      return activity;
    },

    mergeAssignments(assignments) {
      let _assignments = [];

      assignments.forEach((assignment) => {
        if (assignment.flightId) {
          _assignments.push({
            type: "Flight",
            startTime: assignment.flight.effectiveTimeOut,
            endTime: assignment.flight.effectiveTimeIn,
            originIata: assignment.flight.originIata,
            destinationIata: assignment.flight.destinationIata,
            ...assignment,
          });
        } else {
          _assignments.push({
            type: "Ground",
            startTime: assignment.startTime,
            endTime: assignment.endTime,
            originIata: assignment.originIata,
            destinationIata: assignment.destinationIata,
            ...assignment,
          });
        }
      });

      return _assignments.sort(this.compareAssignments);
    },

    overlaps(dayStart, dayEnd, periods) {
      if (periods.length) {
        let periodStart;
        let periodEnd;

        let count = 0;

        periods.forEach((period) => {
          periodStart = new Date(period.start).getTime();
          periodEnd = new Date(period.end).getTime();

          if (dayStart <= periodEnd && dayEnd >= periodStart) {
            count++;
          }
        });

        return count > 0;
      } else {
        return false;
      }
    },

    getDaysRemaining(expirationDate) {
      const daysRemaining =
        (new Date(expirationDate).getTime() - new Date().getTime()) /
        (24 * 60 * 60 * 1000);

      if (daysRemaining > 0) {
        return Math.ceil(daysRemaining);
      } else {
        return 0;
      }
    },

    formatConflictText(previousFlight, flight, employee) {
      const timeIn = this.formatDateTime(previousFlight.effectiveTimeIn);
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const employeeName = `${employee.surname}, ${employee.givenName}`;

      const snapshot = `${employeeName} . Flight ${previousFlight.flightNumber} arrives at ${previousFlight.destinationIata} on ${timeIn}, next flight ${flight.flightNumber} departs from ${flight.originIata} on ${timeOut}`;
      return snapshot;
    },

    compareNames(a, b) {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    },

    compareSpecialAiports(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (b.code < a.code) {
        return 1;
      }
      return 0;
    },

    compareAircrafts(a, b) {
      if (a.registration < b.registration) {
        return -1;
      }
      if (b.registration < a.registration) {
        return 1;
      }
      return 0;
    },

    compareFlights(a, b) {
      if (
        new Date(a.effectiveTimeOut).getTime() <
        new Date(b.effectiveTimeOut).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.effectiveTimeOut).getTime() <
        new Date(a.effectiveTimeOut).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareVaccines(a, b) {
      if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1;
      }
      if (new Date(b.date).getTime() < new Date(a.date).getTime()) {
        return 1;
      }
      return 0;
    },

    comparePassports(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareExemptions(a, b) {
      if (a.key < b.key) {
        return -1;
      }
      if (b.key < a.key) {
        return 1;
      }
      return 0;
    },

    compareAssignments(a, b) {
      if (new Date(a.startTime).getTime() < new Date(b.startTime).getTime()) {
        return -1;
      }
      if (new Date(b.startTime).getTime() < new Date(a.startTime).getTime()) {
        return 1;
      }
      return 0;
    },

    formatName(givenName, surname, format) {
      if (format === "f") {
        return `${givenName} ${surname}`;
      } else if (format === "sub") {
        return `${surname}, ${givenName.substring(0, 1)}`;
      } else {
        return `${surname}, ${givenName}`;
      }
    },

    getLocationLeft(effectiveTimeOut, filterStart, scale) {
      const minutes =
        (new Date(effectiveTimeOut).getTime() -
          new Date(filterStart).getTime()) /
        1000 /
        60;
      return minutes / scale;
    },

    formatWeight(w) {
      return w.toLocaleString();
    },

    formatDate(d) {
      if (d) {
        return new Date(d).toUTCString().substring(5, 16);
      } else {
        return "---";
      }
    },

    formatTime(d) {
      return new Date(d).toUTCString().substring(17, 22);
    },

    formatMinutes(minutes) {
      const _h = Math.floor((minutes % (24 * 60)) / 60);
      const _m = Math.floor((minutes % (24 * 60)) % 60);

      const h = _h < 10 ? `0${_h}` : _h;
      const m = _m < 10 ? `0${_m}` : _m;

      return `${h}+${m}`;
    },

    formatDay(d, scale) {
      if (scale > 8) {
        return new Date(d)
          .toUTCString()
          .toString()
          .substring(5, 8);
      } else {
        return new Date(d).toUTCString().substring(0, 16);
      }
    },

    formatMonth(m) {
      switch (m) {
        case 0:
          return "January";
        case 1:
          return "February";
        case 2:
          return "March";
        case 3:
          return "April";
        case 4:
          return "May";
        case 5:
          return "June";
        case 6:
          return "July";
        case 7:
          return "August";
        case 8:
          return "September";

        case 9:
          return "October";

        case 10:
          return "November";

        case 11:
          return "December";

        default:
          return "---";
      }
    },

    formatMonthShort(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";

        case 9:
          return "OCT";

        case 10:
          return "NOV";

        case 11:
          return "DEC";

        default:
          return "---";
      }
    },

    getCertificateTitle(code) {
      const certificateType = this.company.trainingCertificateTypes.find(
        (ct) => {
          return ct.code === code;
        }
      );

      if (certificateType) {
        return certificateType.title;
      } else {
        return code;
      }
    },

    getFlightRole(role) {
      let _role = {
        role,
        description: `${role} not found`,
        aircraftTypes: ["Passenger"],
      };

      switch (role) {
        case "CLM":
        case "CLM2":
          _role = {
            role,
            description: "Check Loadmaster",
            aircraftTypes: ["Cargo"],
          };

          break;

        case "SLM":
        case "SLM2":
        case "SLM3":
          _role = {
            role,
            description: "Senior Loadmaster",
            aircraftTypes: ["Cargo"],
          };
          break;

        case "LM":
        case "LM2":
        case "LM3":
        case "LM4":
        case "LM5":
          _role = { role, description: "Loadmaster", aircraftTypes: ["Cargo"] };
          break;
        case "LMT":
        case "LMT2":
        case "LMT3":
        case "LMT4":
        case "LMT5":
          _role = {
            role,
            description: "Loadmaster Training",
            aircraftTypes: ["Cargo"],
          };
          break;

        case "SPSR":
        case "SPSR2":
          _role = {
            role,
            description: "Senior PSR",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "CPSR":
        case "CPSR2":
          _role = {
            role,
            description: "Check PSR",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "LPSR":
        case "LPSR2":
        case "LPSR3":
        case "LPSR4":
        case "LPSR5":
          _role = {
            role,
            description: "Lead PSR",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "PSR":
        case "PSR2":
        case "PSR3":
        case "PSR4":
        case "PSR5":
          _role = { role, description: "PSR", aircraftTypes: ["Passenger"] };
          break;
        case "PSRT":
        case "PSRT2":
        case "PSRT3":
        case "PSRT4":
        case "PSRT5":
          _role = {
            role,
            description: "PSR Training",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "PAA":
        case "PAA2":
        case "PAA3":
        case "PAA4":
        case "PAA5":
          _role = {
            role: "PAA",
            description: "PAA",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "POC":
          _role = {
            role: "POC",
            description: "POC",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "GSC":
          _role = {
            role: "GSC",
            description: "GSC",
            aircraftTypes: ["Passenger"],
          };
          break;

        case "PAAT":
        case "PAAT2":
        case "PAAT3":
        case "PAAT4":
        case "PAAT5":
          _role = {
            role,
            description: "PAA Training",
            aircraftTypes: ["Passenger"],
          };
          break;
        case "MX":
        case "MX2":
        case "MX3":
        case "MX4":
        case "MX5":
          _role = {
            role,
            description: "Mechanic",
            aircraftTypes: ["Cargo", "Passenger"],
          };
          break;
        case "JS":
        case "JS1":
        case "JS2":
        case "JS3":
        case "JS4":
        case "JS5":
        case "JS6":
        case "JS7":
        case "JS8":
        case "JS9":
        case "JS10":
          _role = {
            role,
            description: "Jumpseat",
            aircraftTypes: ["Cargo", "Passenger"],
          };
          break;
        default:
          break;
      }

      return _role;
    },

    getFlightRoleGroupName(flightRole) {
      let role = null;

      switch (flightRole) {
        case "CLM":
        case "CLM2":
          role = "CLM";
          break;

        case "SLM":
        case "SLM2":
        case "SLM3":
          role = "SLM";
          break;

        case "LM":
        case "LM2":
        case "LM3":
        case "LM4":
        case "LM5":
          role = "LM";
          break;
        case "LMT":
        case "LMT2":
        case "LMT3":
        case "LMT4":
        case "LMT5":
          role = "LMT";
          break;

        case "SPSR":
        case "SPSR2":
          role = "SPSR";
          break;

        case "CPSR":
        case "CPSR2":
          role = "CPSR";
          break;

        case "LPSR":
        case "LPSR2":
        case "LPSR3":
        case "LPSR4":
        case "LPSR5":
          role = "LPSR";
          break;

        case "PSR":
        case "PSR2":
        case "PSR3":
        case "PSR4":
        case "PSR5":
          role = "PSR";
          break;

        case "PSRT":
        case "PSRT2":
        case "PSRT3":
        case "PSRT4":
        case "PSRT5":
          role = "PSRT";
          break;

        case "PAA":
        case "PAA2":
        case "PAA3":
        case "PAA4":
        case "PAA5":
          role = "PAA";
          break;

        case "POC":
          role = "POC";
          break;
        case "GSC":
          role = "GSC";
          break;

        case "PAAT":
        case "PAAT2":
        case "PAAT3":
        case "PAAT4":
        case "PAAT5":
          role = "PAAT";
          break;

        case "MX":
        case "MX2":
        case "MX3":
        case "MX4":
        case "MX5":
          role = "MX";
          break;

        case "JS1":
        case "JS2":
        case "JS3":
        case "JS4":
        case "JS5":
        case "JS6":
        case "JS7":
        case "JS8":
        case "JS9":
        case "JS10":
          role = "JS";
          break;

        default:
          break;
      }

      return role;
    },

    getGroundAssignmentLabelStyle(groundAssignment) {
      // groundAssignmentCategories: [
      //   "Stand By",
      //   "Station Assignment",
      //   "Special Assignment",
      //   "Travel",
      //   "Layover",
      //   "Training",
      //   "Sick",
      //   "Quarantine",
      //   "Quarantine - Day OFF",
      //   "Vacation",
      //   "Holiday",
      //   "Floating Holiday",
      //   "Leave",
      //   "LOA",
      // ],

      let style = {
        backgroundColor: "#C89762",
        color: "#FFFFFF",
      };

      switch (groundAssignment.category) {
        case "Stand By":
          style = {
            backgroundColor: "#F59608",
            color: "#FFFFFF",
          };
          break;

        case "Layover":
          if (groundAssignment.confirmed) {
            style = {
              backgroundColor: "#C89762",
              color: "#FFFFFF",
            };
          } else {
            style = {
              backgroundColor: "#C89762",
              color: "#FF0000",
            };
          }

          break;

        case "Travel":
          if (groundAssignment.confirmed) {
            style = {
              backgroundColor: "#379fe1",
              color: "#FFFFFF",
            };
          } else {
            style = {
              backgroundColor: "#379fe1",
              color: "#FF0000",
            };
          }

          break;
        case "Training":
          style = {
            backgroundColor: "#002663",
            color: "#FFFFFF",
          };
          break;
        case "Leave":
          style = {
            backgroundColor: "#002663",
            color: "#FFFFFF",
          };
          break;

        case "Sick":
          style = {
            backgroundColor: "#598662",
            color: "#FFFFFF",
          };
          break;
        case "Vacation":
        case "Holiday":
        case "Floating Holiday":
          style = {
            backgroundColor: "#F7490E",
            color: "#FFFFFF",
          };
          break;

        case "LOA":
          style = {
            backgroundColor: "#F7490E",
            color: "#FFFFFF",
          };
          break;

        case "Station Assignment":
          if (groundAssignment.linkedFlightId) {
            if (groundAssignment.flight) {
              style = {
                backgroundColor: "#C89762",
                color: "#FFFFFF",
                borderBottom: "6px solid #641E16",
              };
            } else {
              style = {
                backgroundColor: "#C89762",
                color: "#FF0000",
                borderStyle: "solid",
                borderColor: "#FF0000",
                borderWidth: "1px 1px 8px 1px",
              };
            }
          }

          break;
        case "Special Assignment":
          break;
        case "Quarantine":
        case "Quarantine - Day OFF":
          style = {
            backgroundColor: "#9A071C",
            color: "#FFFFFF",
          };
          break;
        case "Do Not Use":
          style = {
            backgroundColor: "#f56308",
            color: "#FFFFFF",
          };
          break;

        default:
          break;
      }

      return style;
    },

    formatDateTime(d) {
      if (d) {
        const d1 = new Date(d).toUTCString().toString();

        return `${d1.substring(5, 17)} - ${d1.substring(16, 22)}`;
      } else {
        return "---";
      }
    },

    formatAssignmentSnapshot(assignment) {
      if (assignment.type === "Ground") {
        return this.formatGroundAssignmentSnapshot(assignment);
      } else {
        return this.formatFullFlightSnapshot(assignment.flight);
      }
    },

    formatFlightSnapshot(flight) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);

      const snapshot = `${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata}-${flight.destinationIata} . ${timeOut} Z`;
      return snapshot;
    },

    formatGroundAssignmentSnapshot(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);
      let snapshot = "??";

      switch (assignment.category) {
        case "Station Assignment":
          snapshot = `${assignment.originIata} . ${assignment.subCategory} . ${timeOut} Z - ${timeIn} Z`;

          break;
        case "Special Assignment":
          snapshot = `${assignment.category}  . ${assignment.originIata} . ${timeOut} Z`;

          break;
        case "Leave":
        case "LOA":
          snapshot = `${assignment.category} . ${assignment.subCategory} . ${assignment.originIata} .  ${timeOut} Z`;
          break;

        case "Layover":
          snapshot = `${assignment.category} . ${assignment.originIata} . ${timeOut} Z`;
          break;

        case "Stand By":
        case "Quarantine":
        case "Quarantine - Day OFF":
          snapshot = `${assignment.category} . ${assignment.originIata} . ${timeOut} Z`;
          break;

        case "Sick":
          snapshot = `${assignment.category} . ${assignment.originIata} . ${timeOut} Z`;
          break;
        case "Training":
          snapshot = `${assignment.category} . ${assignment.subCategory} . ${assignment.originIata} . ${timeOut} Z`;
          break;
        case "Travel":
          snapshot = `${assignment.category} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z`;
          break;
        case "Do Not Use":
          snapshot = `${assignment.category}  . ${assignment.originIata} . ${timeOut} Z`;

          break;

        default:
          snapshot = `${assignment.category} . ${timeOut} Z`;

          break;
      }

      return snapshot;
    },

    formatStationAssignmentSnapshot(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);

      return `${assignment.originIata} . ${
        assignment.subCategory
      } . ${timeOut} Z - ${timeIn} Z . ${
        assignment.flights.length
      } Assignment(s) . ${this.formatMinutes(assignment.duration)}`;
    },

    formatFlightSnapshotShort(flight) {
      const snapshot = `${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata}-${flight.destinationIata}`;

      return snapshot;
    },

    formatGroundAssignmentSnapshotShort(assignment) {
      let snapshot = "??";

      switch (assignment.category) {
        case "Station Assignment":
          snapshot = `${assignment.subCategory} . ${assignment.originIata}`;

          break;
        case "Special Assignment":
          snapshot = ` ${assignment.category}  . ${assignment.originIata}`;

          break;
        case "Leave":
        case "LOA":
          snapshot = ` ${assignment.category} . ${assignment.subCategory}`;
          break;

        case "Layover":
          snapshot = ` ${assignment.category} . ${assignment.originIata}`;
          break;

        case "Quarantine":
          snapshot = ` ${assignment.category} . ${assignment.originIata}`;
          break;

        case "Sick":
          snapshot = ` ${assignment.category} . ${assignment.originIata}`;
          break;
        case "Training":
          snapshot = ` ${assignment.category} . ${assignment.subCategory} . ${assignment.originIata}`;
          break;
        case "Travel":
          snapshot = ` ${assignment.category} . ${assignment.originIata} - ${assignment.destinationIata}`;
          break;
        case "Do Not Use":
          snapshot = ` ${assignment.category} . ${assignment.originIata}`;
          break;

        default:
          snapshot = ` ${assignment.category}`;

          break;
      }

      return snapshot;
    },

    formatFullFlightSnapshot(flight) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const timeIn = this.formatTime(flight.effectiveTimeIn);

      const snapshot = `${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

      return snapshot;
    },

    getFlightRoleStatusDescription(flightAssignment) {
      const snapshot = this.formatFullFlightSnapshot(flightAssignment.flight);

      if (flightAssignment.notification.sent) {
        // Notification sent
        if (flightAssignment.notification.acknowledged) {
          if (flightAssignment.notification.flightSnapshot === snapshot) {
            // Notification sent, acknowledged and current
            return "Notification sent and acknowledged";
          } else {
            // Notification sent, acknowledged but not current
            return "Notification sent and acknowledged. Flight data changed after acknowledgement.";
          }
        } else {
          // Notification sent but not acknowledged
          return "Notification sent but not acknowledged.";
        }
      } else {
        // Notification not sent
        return " Notification not sent.";
      }
    },
  },
};
